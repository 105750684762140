// 
// nav.scss
//

.nav-tabs,.nav-pills {
    > li {
        > a {
            color: $dark;
            font-weight: $font-weight-semibold;
            &.active{
                color: $primary;
            }
        }
    }
}

.nav-pills {
    > a {
        font-weight: $font-weight-semibold;
    }
}


//Navtab custom


.tab-content {
    padding: 20px 0 0 0;
}  

/* Vertial tab */
.tabs-vertical-env {

    .nav.tabs-vertical {
      .nav-link {
        color: $dark;
        &.active{
          background-color: $primary;
          color: $white;
        }
      }
    }
}



// Nav bordered

// Nav bordered

.tabs-bordered {
    border-bottom: 2px solid rgba($text-muted, 0.2) !important;
    .nav-item {
        margin-bottom: -2px;
    }

    li {
        a {
            border: 0 !important;
            padding: 10px 20px !important;
            &.active {
                border-bottom: 2px solid $primary !important;
            }
        }
    }   
}

